<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>

<style lang="scss" scoped>
#book-footer-temp {
  .btn {
    height: 30px !important;
  }
}
.card-custom {
  .body {
    .fixed {
      position: fixed;
      width: 77.1rem;
      background-color: #ffffff;
      left: 23.6rem;
      z-index: 1;
      top: 15%;
      .search {
        margin-left: 2% !important;
      }
    }
    .move-below {
      margin-top: 13% !important;
    }
  }
}
</style>
<template>
  <div class="card card-custom">
    <div class="card-body mb-10">
      <div class="row">
       
        <div class="col-md-3 pr-0" v-if="currentUser.u.is_admin">
          <div class="form-group">
            <label for="Service Provider">Service Provider</label>
            <select v-model="serviceProviderOutlet" name="" class="form-control" id=""
            @change="loadSpServices()">
              <option value="all">All</option>
              <option
                v-for="(citem, index) in getClients"
                :key="index"
                :value="citem.id"
              >
                {{ citem.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3 pr-0" v-if="currentUser.u.is_admin">
          <div class="form-group">
            <label for="Service Provider">Location</label>
            <select v-model="serviceOutlet" name="" class="form-control" id="">
              <option value="all">All</option>
              <option
                v-for="(citem, index) in getSpClients"
                :key="index"
                :value="citem.id"
              >
                {{ citem.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-3 pr-0" v-if="!currentUser.u.is_client&&!currentUser.u.is_admin">
          <div class="form-group">
            <label for="location">Location</label>
            <select v-model="serviceOutlet" name="" class="form-control" id="">
              <option value="all">All</option>
              <option
                v-for="(citem, index) in getClients"
                :key="index"
                :value="citem.id"
              >
                {{ citem.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <button
            class="btn btn-outline-info"
            @click="loadApiData()"
            style="margin-top: 25px"
          >
            <i class="fa fa-search"></i> Search
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 pr-0" v-if="!currentUser.u.is_client">
          <div class="form-group">
            <div>
              <span>
                Latest Version:
                <span style="font-weight:900;">{{ latest_version }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" style="background:white">
        <table class="table table-striped">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th colspan="3"></th>
            </tr>

            <tr>
              <th class="text-left">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  v-model="selectAll"
                  @change="selectAllApiKeys()"
                />
              </th>
              <th>SN</th>
              <th>Location</th>
              <th>Label</th>
              <th class="text-center">Current Version</th>

              <th class="text-center">Scheduled for update</th>

              <th style="width:20%">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loadingData && apikeyversionlist.length > 0">
            <tr v-for="(item, index) in apikeyversionlist" :key="index">
              <td class="text-left">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  v-model="item.checked"
                  @change="itemSelect(item, $event, item.id)"
                />
              </td>
              <td>{{ ++index }}</td>
              <td>{{ item.client_name }}</td>
              <td>{{ item.label }}</td>
              <th class="text-center">{{ item.current_version }}</th>

              <td class="text-center">
                <span
                  class="text-info text-center"
                  style="font-weight:900;"
                  v-if="
                    item.current_version == latest_version &&
                      latest_version != ''
                  "
                  >Up to date</span
                >
                <i
                  class="fa fa-times text-danger"
                  v-else-if="!item.scheduled_for_update"
                ></i>
                <i
                  class="fa fa-check text-success text-center"
                  v-else-if="item.scheduled_for_update"
                ></i>
              </td>

              <td style="width:100px">
                <button
                  @click="scheduleapikey(item)"
                  class="btn btn-sm btn-primary mr-3 mt-2"
                  style="cursor: pointer;"
                >
                  Schedule
                </button>
                <button
                  @click="unscheduleapikey(item)"
                  class="btn btn-sm btn-danger mr-3 mt-2"
                  style="cursor: pointer;"
                >
                  Unschedule
                </button>
              </td>
            </tr>
          </tbody>

          <tbody v-if="!loadingData && apikeyversionlist.length < 1">
            <tr>
              <td colspan="8" class="text-center">No record(s) found</td>
            </tr>
          </tbody>

          <tbody v-if="loadingData">
            <tr>
              <th class="text-center" colspan="10">
                <vDataLoader></vDataLoader>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <hr v-if="pagination.total_page >= 1" />
      <vl-pagination
        v-on:selected="selectedPage"
        v-on:previous="previousPage"
        v-on:first="firstPage"
        v-on:last="lastPage"
        v-on:next="nextPage"
        v-if="pagination.total_page >= 1"
        :pagination="pagination"
      ></vl-pagination>
      <apikey
        :key="key"
        v-if="ModalShow"
        v-on:closemodal="closeModal"
        :show-modal="ModalShow"
        :apikey="apikeyData"
        :is_edit="is_edit"
        :apikeyid="apikeyid"
        :is_view="is_view"
        :is_disabled="is_disabled"
      ></apikey> -->
    </div>
    <div
      id="book-footer-temp"
      v-if="this.checked_list.length > 0"
      style="position: fixed; bottom: 2.5rem; width: calc(78vw)"
    >
      <div class="card">
        <div class="card-body m-2">
          <button
            class="btn btn-sm btn-success mr-5"
            style="float: right"
            @click="update()"
          >
            Schedule All
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import {
  FETCH_APIKEY_VERSION,
  FETCH_CLIENTS,
  FETCH_SERVICES_CLIENTS,
  UPDATE_APIKEY_VERSION,
  UPDATE_APIKEY_VERSION_LISTS,
  UNSCHEDULE_APIKEY_VERSION
} from "@/core/services/store/actions.type";
// import apikey from "@/pages/backend/apikey/apikeymodal";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
// import vlPagination from "@/includes/table/Pagination";

export default {
  computed: {
    ...mapGetters(["currentUser", "getApikeyVersionData", "getClients","getSpClients"])
  },
  mounted() {
    // this.pagination = {
    //   total_records: 0,
    //   total_page: 0,
    //   current_page: 1,
    //   per_page_data: 1,
    //   received_per_page_data: 1
    // };
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.loadClients();
    this.loadApiData();
  },
  //   components: {
  //     // apikeyversion,
  //     // vlPagination
  //   },
  methods: {
    loadApiData() {
      let body={}
      if(this.currentUser.u.is_admin){
        body={
          service_outlet: this.serviceOutlet,
          service_provider_outlet:this.serviceProviderOutlet
        }
      }
      else{
        body={
          service_outlet: this.serviceOutlet
        }
      }
      this.$store
        .dispatch(FETCH_APIKEY_VERSION, body)
        .then(data => {
          if (data.success == true) {
            this.latest_version = data.latest_version;
            this.apikeyversionlist = data.data;
          }
        });
    },
    async loadClients() {
      await this.$store.dispatch(FETCH_CLIENTS);
    },
    // async loadApiData() {
    //   //   let page = this.pagination.current_page;
    //   await this.$store.dispatch(FETCH_APIKEY_VERSION);
    // },
    //  in case of admin fetchclients fetchses sp and 
    //  this fetches locations of that client
    loadSpServices(){
      this.serviceOutlet="all";
      this.$store.dispatch(FETCH_SERVICES_CLIENTS, this.serviceProviderOutlet);   
    },
    selectAllApiKeys() {
      if (this.selectAll) {
        this.show_update_button = true;
        this.apikeyversionlist.filter(item => {
          this.apikeyversionlist;
          this.checked_list = [...this.apikeyversionlist];
          return (item.checked = true);
        });
      } else {
        this.show_update_button = false;
        this.apikeyversionlist.filter(item => {
          this.checked_list = [];
          return (item.checked = false);
        });
      }
    },
    itemSelect(item, $e, id) {
      if ($e.target.checked) {
        this.checked_list.push(item);
      } else {
        let index = this.checked_list.findIndex(item => item.id === id);
        if (index > -1) {
          this.checked_list.splice(index, 1);
        }
      }
    },

    //   console.log($e.target.checked);
    // },

    scheduleapikey(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You want to schedule update for " + item.label,
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes"
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          this.$store
            .dispatch(UPDATE_APIKEY_VERSION, item.id)
            .then(data => {
              this.$toastr.s(data.msg);

              this.loadApiData();
            })
            .catch(err => {
              this.$toastr.e(err.msg);
            });
        }
      });
    },
    unscheduleapikey(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You want to unschedule update for " + item.label,
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes"
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          this.$store
            .dispatch(UNSCHEDULE_APIKEY_VERSION, item.id)
            .then(data => {
              this.$toastr.s(data.msg);

              this.loadApiData();
            })
            .catch(err => {
              this.$toastr.e(err.msg);
            });
        }
      });
    },
    update() {
      var table_datas_ = this.apikeyversionlist.filter(
        service => service.checked == true
      );
      // console.log(table_datas_);
      if (table_datas_.length == 0) {
        this.$toastr.e("Please select at least one");
      } else {
        this.errors = [];
        this.$swal({
          title: "Are you sure?",
          text: "You want to update for all selected data!",
          buttons: true,
          dangerMode: true,
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Yes"
        }).then(willDelete => {
          if (willDelete.isConfirmed) {
            this.$store
              .dispatch(UPDATE_APIKEY_VERSION_LISTS, table_datas_)
              .then(data => {
                this.$toastr.s(data.msg);
                this.selectAll = false;
                this.checked_list = [];
                this.loadApiData();
              })
              .catch(err => {
                this.errors = err;
                this.selectAll = false;
              });
          }
        });
        // this.loadingButton(ref);
        // let _data = {};
        // _data = table_datas_
      }
    }

    // selectedPage(n) {
    //   this.pagination.current_page = n;
    //   this.loadApiData();
    // },
    // nextPage() {
    //   this.pagination.current_page = this.pagination.current_page + 1;
    //   this.loadApiData();
    // },
    // lastPage() {
    //   this.pagination.current_page = this.pagination.total_page;
    //   this.loadApiData();
    // },
    // firstPage() {
    //   this.pagination.current_page = 1;
    //   this.loadApiData();
    // },
    // previousPage() {
    //   this.pagination.current_page = this.pagination.current_page - 1;
    //   this.loadApiData();
    // },

    // deleteItem(id) {
    //   this.$store
    //     .dispatch(DELETE_APIKEY, id)
    //     .then(() => {
    //       this.$toastr.s("Group Deleted ");
    //       this.$store.dispatch(FETCH_APIKEY);
    //     })
    //     .catch(err => {
    //       this.errors = err;
    //     });
    // },
  },
  data() {
    return {
      loadingData: false,
      // ModalShow: false,
      latest_version: "",
      apikeyversionlist: [],
      selectAll: false,
      serviceOutlet: "all",
      serviceProviderOutlet:"all",
      show_update_button: false,
      checked_list: [],
      // pagination: {
      //   total_records: 0,
      //   total_page: 0,
      //   current_page: 1,
      //   per_page_data: 15,
      //   received_per_page_data: 15
      // },
      // is_view: false,
      // is_disabled: false,
      // key: 0,
      columns: [
        {
          name: "Apikey name",
          width: "",
          label: "name",
          class: ""
        }
      ],
      is_edit: false,
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Workstation Versions",
          route: ""
        }
      ]
    };
  }
};
</script>
